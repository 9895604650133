export const environments = [
  {
    id: '',
    name: 'None',
    path: null,
    format: '.hdr'
  },
  {
    id: 'venice-sunset',
    name: 'Venice Sunset',
    path: 'assets/environment/venice_sunset_1k.hdr',
    format: '.hdr'
  },
  {
    id: 'footprint-court',
    name: 'Footprint Court (HDR Labs)',
    path: 'assets/environment/footprint_court_2k.hdr',
    format: '.hdr'
  },
  {
    id: 'Hauser-Wirth-Somerset-Field-01',
    name: 'Houser Wirth (HDR)',
    path: 'assets/environment/Hauser-Wirth-Somerset-Field-01.hdr',
    format: '.hdr'
  },
  {
    id: 'greenwich_park_02_4k',
    name: 'greenwich park 2 4k',
    path: 'assets/environment/greenwich_park_02_4k.hdr',
    format: '.hdr'
  },

  {
    id: 'greenwich_park_03_4k',
    name: 'greenwich park 3 4k',
    path: 'assets/environment/greenwich_park_03_4k.hdr',
    format: '.hdr'
  },

  {
    id: 'greenwich_park_4k',
    name: 'greenwich park 1 4k',
    path: 'assets/environment/greenwich_park_4k_Rotated4.hdr',
    format: '.hdr'
  },
  {
    id: 'greenwich_park_8k',
    name: 'greenwich park 1 8k',
    path: 'assets/environment/spiaggia_di_mondello_8k.hdr',
    format: '.hdr'
  }
];


