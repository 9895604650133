import * as THREE from 'three';
import LIGHTS from "./LightDB";
import {DirectionalLight} from "three";

class LightFactory {
    _lights = [];
    _scene;
    _gui;

    constructor(scene, gui) {
        this._scene = scene;
        this._gui = gui;
    }


    createLights = () => {
        let index = this._lights.length;
        LIGHTS.forEach(item => {
            const light = item.light;
            if (item.light instanceof THREE.SpotLight) {
                console.log("SpotLight is added -", item.name);
                light.position.copy(item.position);
                light.target.position.copy(item.target);
                light.angle = item.angle;
                light.penumbra = item.penumbra;
                light.decay = item.decay;
                light.distance = item.distance;
                light.intensity = item.intensity;
                //light.power = item.intensity * Math.PI;
                light.name = item.name;
                this._lights[index++] = light;

            } else if (item.light instanceof THREE.PointLight) {
                console.log("PointLight is added -", item.name);
                light.position.copy(item.position);
                light.decay = item.decay;
                light.distance = item.distance;
                light.intensity = item.intensity;
                //light.power = item.intensity * Math.PI;
                light.name = item.name;
                this._lights[index++] = light;
            } else if(item.light instanceof DirectionalLight) {

                light.position.copy(item.position);

                light.intensity = item.intensity;
                //light.power = item.intensity * Math.PI;
                light.name = item.name;
                this._lights[index++] = light;
            }


        })
    }

    addToScene = () => {
        this._lights.forEach(item => {
            this._scene.add(item);
            if (item.target) this._scene.add(item.target);
            if (this._gui) {
                this._gui.addLight(item.name, item);
            }

        })
    }
    changeAllIntensities = (intensity)=> {
        this._lights.forEach(item => {
           item.intensity = intensity;

        })
    }
}

export default LightFactory;


