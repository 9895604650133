import {Euler, Vector3, Scene, SpotLight, PointLight, DirectionalLight} from "three";
import {thisIsMobile} from "../../../globalConstants/GlobalConstants";

let LIGHTS = [];

// if (thisIsMobile) {
if (true) {

    //mobile version
    LIGHTS = [

       /* {
            name: 'W1',
            light: new SpotLight(),
            position: new Vector3(0, 1.95, 0),
            target: new Vector3(0, 0, -7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.77,
            penumbra: .3,
            decay: .38,
            distance: 20,
            // power:20,
        },

        {
            name: 'W3',
            light: new SpotLight(),
            position: new Vector3(0, 1.95, 0),
            target: new Vector3(0, 0, 7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.77,
            penumbra: .3,
            decay: .38,
            distance: 20,
            // power:20,
        },

        {
            name: 'W4',
            light: new SpotLight(),
            position: new Vector3(0, 1.95, 0),
            target: new Vector3(-7.73, 0, 0),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.77,
            penumbra: .3,
            decay: .38,
            distance: 20,
            // power:20,
        },
        {
            name: 'W2T1',
            light: new SpotLight(),
            position: new Vector3(5.73, 1.95, -5.37),
            target: new Vector3(7.73, 0, -5.37),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.77,
            penumbra: .3,
            decay: .5,
            distance: 6,
            //power:20,
        },

        {
            name: 'W2P1',
            light: new SpotLight(),
            position: new Vector3(5.73, 1.95, 5.39),
            target: new Vector3(7.73, 0, 5.39),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.77,
            penumbra: .3,
            decay: .5,
            distance: 6,
            // power:20,
        },*/
        {
            name: 'PointLightCenter',
            light: new PointLight(),
            position: new Vector3(0, 1, 0),
            color: 0xffffff,
            intensity: 2.5,
            decay: .65,
            distance: 15,
            // power:20,
        },

        {
            name: 'PointLightChandlier',
            light: new PointLight(),
            position: new Vector3(0, 1, 0),
            color: 0xffffff,
            intensity: 4.1,
            decay: 1.3,
            distance: 2.5,
            // power:20,
        },

        {
            name: 'PointLightCeiling2',
            light: new PointLight(),
            position: new Vector3(0, 3.4, 0),
            color: 0xffffff,
            intensity: 2,
            decay: 1.1,
            distance: 8.3,
            // power:20,
        },
    ]
} else {

    LIGHTS = [
        {
            name: 'W1P1',
            light: new SpotLight(),
            position: new Vector3(-5.25, 0, -5.7),
            target: new Vector3(-5.25, 0, -7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            // power:20,
        },

        {
            name: 'W1P2',
            light: new SpotLight(),
            position: new Vector3(-1.75, 0, -5.7),
            target: new Vector3(-1.75, 0, -7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.85,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            // power:20,
        },

        {
            name: 'W1P3',
            light: new SpotLight(),
            position: new Vector3(1.75, 0, -5.7),
            target: new Vector3(1.75, 0, -7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.85,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            //power:20,
        },
        {
            name: 'W1P4',
            light: new SpotLight(),
            position: new Vector3(5.25, 0, -5.7),
            target: new Vector3(5.25, 0, -7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            //power:20,
        },

        {
            name: 'W2T1',
            light: new SpotLight(),
            position: new Vector3(5.73, 0, -5.37),
            target: new Vector3(7.73, 0, -5.37),
            color: 0xffffff,
            intensity: 1.2,
            angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            //power:20,
        },

        {
            name: 'W2P1',
            light: new SpotLight(),
            position: new Vector3(5.73, 0, 5.39),
            target: new Vector3(7.73, 0, 5.39),
            color: 0xffffff,
            intensity: 1.2,
            angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            // power:20,
        },
        {
            name: 'W3P1',
            light: new SpotLight(),
            position: new Vector3(5.25, 0, 5.73),
            target: new Vector3(5.25, 0, 7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            //power:20,
        },

        {
            name: 'W3P2',
            light: new SpotLight(),
            position: new Vector3(1.75, 0, 5.73),
            target: new Vector3(1.75, 0, 7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.85,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            // power:20,
        },

        {
            name: 'W3P3',
            light: new SpotLight(),
            position: new Vector3(-1.75, 0, 5.73),
            target: new Vector3(-1.75, 0, 7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.85,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            // power:20,
        },

        {
            name: 'W3P4',
            light: new SpotLight(),
            position: new Vector3(-5.25, 0, 5.73),
            target: new Vector3(-5.25, 0, 7.73),
            color: 0xffffff,
            intensity: 1.2,
            angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            // power:20,
        },
        {
            name: 'W4P1',
            light: new SpotLight(),
            position: new Vector3(-5.73, 0, 5.25),
            target: new Vector3(-7.73, 0, 5.25),
            color: 0xffffff,
            intensity: 1.2,
            angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            // power:20,
        },

        {
            name: 'W4P2',
            light: new SpotLight(),
            position: new Vector3(-5.73, 0, 1.75),
            target: new Vector3(-7.73, 0, 1.75),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.85,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            //power:20,
        },
        {
            name: 'W4P3',
            light: new SpotLight(),
            position: new Vector3(-5.73, 0, -1.75),
            target: new Vector3(-7.73, 0, -1.75),
            color: 0xffffff,
            intensity: 1.2,
            angle: 0.85,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            //power:20,
        },

        {
            name: 'W4P4',
            light: new SpotLight(),
            position: new Vector3(-5.73, 0, -5.25),
            target: new Vector3(-7.73, 0, -5.25),
            color: 0xffffff,
            intensity: 1.2,
            angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,
            // power:20,
        },

        {
            name: 'PointLightCenter',
            light: new PointLight(),
            position: new Vector3(0, -.084, 0),
            color: 0xffffff,
            intensity: 1.9,
            decay: .65,
            distance: 10,
            // power:20,
        },

        {
            name: 'PointLightChandlier',
            light: new PointLight(),
            position: new Vector3(0, 1.95, 0),
            color: 0xffffff,
            intensity: 5.4,
            decay: 1.49,
            distance: 7,
            // power:20,
        },

      /*  {
            name: 'SunBottom',
            light: new DirectionalLight(),
            position: new Vector3(0, 1.95, 10),
            target: new Vector3(0, 0, -7.73),
            color: 0xffffff,
            intensity: 1,
           /!* angle: 1.04,
            penumbra: .43,
            decay: 1.34,
            distance: 7.7,*!/
            // power:20,
        },*/

        /* {
             name:'PointW2W3',
             light: new PointLight(),
             position: new Vector3(7.42, 0, 7.4),
             color:0xffffff,
             intensity:1.2,
             decay:.13,
             distance:12,
             // power:20,
         },*/
    ]
}
//desktop version


export default LIGHTS;
