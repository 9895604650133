import {ANNOTATION_VIDEO_DISPLAY, ANNOTATION_PARENT, VIDEO_PLAYING_MODE} from '../../globalConstants/GlobalConstants';
import * as THREE from 'three';

export const ANNOTATION_CONTACT_TEXT = '';
export const CONTACT_URL = 'WEBSITE';
export const YOUTUBE = 'YOUTUBE';
export const INSTAGRAM = 'INSTAGRAM';
export const SPOTIFY = 'SPOTIFY';
export const TWITTER = 'TWITTER';
export const TIKTOK = 'TIKTOK';
export const IMDB = 'IMDB';
export const FACEBOOK = 'FACEBOOK';
export const ENQUIRE_EMAIL = 'ENQUIRE';
export const LINKED_IN = 'LINKEDIN';
export const HAS_MEDIA_CAROUSEL_LINK = 'MORE';
export const SEND_TO_EMAIL = 'SEND TO';

const AnnotationsDB = [

    /* Elle Baez Annotations */
    {
        paintingId: 'W1P1',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W1P1a.jpg`,
        headerText: '<h5>BIANCA ROGOFF</h5>',
        bodyText: `Bianca Rogoff is a proud queer, mixed-race sister, friend, daughter, singer, poet, woe (“working on excellence” coined by Drake, but personally introduced to her by Adrienne Maree Brown), community healer, traveler, pleasure activist, and leader. Her art is fueled from her gut and her undying interest in relaying the inexplicable human condition through language, touch, and expression beyond intellect.<br>In Bianca’s eyes, body positivity means that size does not dictate health, nor does it equate to healthy habits. This is why self-acceptance is at the root of her relationship with her body. “Thinness has been a direct marker to whiteness and including “body positivity” in your anti-racist work on an internalized, structural, and cultural level will be extremely liberating for every community.”<br>Her current life goal is to create a body of work that lasts past her lifetime while still being able to touch as many people’s lives as possible in this lifetime. Bianca has stopped equating her value with her size. She never thought twice about it when she was younger. “I am a work of art because I am a healer and my heart is truly kind. I will go out of my way to help someone and make someone feel loved. I used to do it at the expense of myself, but I'm learning to take myself along for the ride (and it's been an exciting journey).”`,
        ContactURL: 'https://www.biancarogoff.com',
        instagramUrl: 'https://www.instagram.com/bianca.rogoff',
    },
    {
        paintingId: 'W1P2',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W1P2a.jpg`,
        headerText: '<h5>CONSTANCE SMITH</h5>',
        bodyText: `Constance Smith is a model and influencer living in Brooklyn, NY. She believes in being her authentic self and using her platform to show what it is like to live and love oneself with a larger body. Her positivity flows throughout her body, mind, and spirit.<br>She strives to always submit and pitch herself to all brands unrelentingly because she knows she can get that “yes.” Constance shows her strength and perseverance in her content in order to be a role model in the media. Her life goal is to leave a legacy by impacting others to be authentically themselves.<br>While listening to the song “Paint Me”, Constance envisions herself as a flower constantly in bloom as it’s being captured, flaws and all. “I am a work of art because I embrace all of me.”`,
        instagramUrl: 'https://www.instagram.com/lunamodela',
    },
    {
        paintingId: 'W1P3',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W1P3a.jpg`,
        headerText: '<h5>PRISCILLA DEL CASTILLO</h5>',
        bodyText: `Priscilla Del Castillo is a Latinx model, activist, and micro-influencer from Miami, Florida. As a model in New York City, Priscilla was most recently featured in a Target campaign and aims to spread positivity and happiness with her activism.<br>Priscilla’s favorite movie is The Great Gatsby; the movie features her favorite leading lady, Daisy, and she envisions a plus-size version of Daisy gracing the big screen soon.<br>Priscilla would tell her 10 year old self to have the confidence portrayed in the “Paint Me” music video and collection when it comes to haters! “Stop listening to haters, they eventually want to be your friend.” Priscilla states that she is a work of art “because I’m unapologetically me.”`,
        instagramUrl: 'https://www.instagram.com/thepriscilladelcastillo/',
    },
    {
        paintingId: 'W1P4',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W1P4a.jpg`,
        headerText: '<h5>MORGAN LOUISE</h5>',
        bodyText: `Morgan Louise is a model and influencer who moved to NYC with a suitcase, a couple of months worth of rent money, and a dream. She believes in taking big leaps of faith, because the universe loves and rewards courage. “If you jump into the abyss you’ll discover it’s a feather bed.”<br>Her personal journey of body acceptance means the difference between living a life where she was obsessed with shrinking herself or living a life full of big adventures. She dreams of buying and restoring an old European farmhouse or castle and converting it into a bed and breakfast with a big garden, cute rescued farm animals, and cooking and serving farm-to-table meals to travelers from all over the world.<br>“I am a work of art because I am a collection of all of my personal experiences, both pleasant and traumatic--the full spectrum. The way my soul has navigated these experiences makes up who I am, imbues my life with meaning, and informs how I operate in the world around me.”`,
        ContactURL: 'http://morganlouise.plus',
        instagramUrl: 'https://www.instagram.com/morganlouise.plus',
    },
    // WALL 2
    {
        paintingId: 'W2V1',
        videoId: 'W2V1',
        videoURL: 'W2V1.mp4',
        standaloneVideoElementId: 'W2V1',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE | ANNOTATION_VIDEO_DISPLAY.ANNOTATION_TEXT,
        displayMesh: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_WITH_BUTTONS,
        videoTextureEncoding: THREE.LinearEncoding,
        iframeSource: 'https://www.youtube.com/embed/b9sSnu7nqpE?modestbranding=1&playsinline=1&showinfo=0&enablejsapi=1&origin=${window.location.origin}&widgetid=1',
        headerText: '<h5>Paint Me</h5>',
        emailSubjectText: `Paint Me`,
        bodyText: `Watch & Share “Paint Me” on Youtube<br>STARRING<br>“Rose”/Self: Elle Baez<br>“Jack”: Fernando Bruno<br>Director: Carina Allen<br>Producer & Conception: Elle Baez<br>Co-Producer: Jeyh Daniel<br>DP: Autumn Moran<br>AD: Chanel Pinnock<br>Production Designer: Zachary Olewnicki<br>HMU: Erica Janssen<br>AC: Dan Foley<br>Costume Designer: Virginia Baez<br>Editor: Carina Allen & Elle Baez<br>Visual Effects: Mark DePasquale<br>Creative Consultant: Gabriella Concepcion<br>Sketch Artist: OB Bennington<br>Transportation: Ricardo Baez<br>PA: Matt Duffy<br>PA: Brandon Baez<br>Original song by Lauren Baez and Joseph Barbosa<br>Produced by Tiger Darrow<br>Mastered by Torna`,
        videoButtonScale: {x:.3,y:.3,z:1},
        videoButtonPos: {x:7.62,y:0,z:-3.194},
        videoLoop:true,

    },
    {
        paintingId: 'W2P1',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W2P1.jpg`,
        headerText: '<h5>ELLE BAEZ</h5>',
        bodyText: `Independent music artist, songwriter, actor, and model, Elle Baez, is becoming a voice for women everywhere with her raw delivery of beautiful pop-soul vocals that paint a striking vulnerable portrait of her life, song by song.<br>Baez mixes her advocacy and storytelling with rich soundscapes that use elements of pop, latin, r&b, jazz, and funk to create an exciting new energy that supports Elle’s stunning vocals. Her original music has the power to evoke a broken heart to find the strength to overcome hard times or get your friends ready to party with her infectious motto of self-love and captivating hooks.<br>She began making music to break the stereotype she found herself constantly placed in by casting agents and acting professors. In college, she was told in front of her peers that she “needed to lose the weight” by an Oscar-award winning actress and professor. And this was after two years of disordered eating triggered by the culture and pressures of her performance program. But Elle takes these scars and transforms them into cathartic art and gratuitous love for herself.<br>Her ethos is simple: be curvy, be sexy, and above all, be confident. As a co-founder of the Curvy Artists Collective, a home and platform promoting size inclusivity in the arts, she has made it her mission to help her big girl sisters achieve success. Elle’s newest single “Paint Me” is the body positive anthem we have all been yearning for.  Elle Baez is a tour-de force and on the rise, paving her own way through the industry with her authentic story and empowering music.`,
        ContactURL: 'https://www.ellebaez.com',
        instagramUrl: 'https://www.instagram.com/elle_baez',
        youtubeUrl: 'https://www.youtube.com/channel/UCTGFeu-I44WVtRwnh2RroHg',
        spotifyUrl: 'https://open.spotify.com/artist/6my64u4SYBvDcURMWurbfs?si=1s-ycFyTTfCTHdkS580x5g',
    },
    {
        paintingId: 'W2T1',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W2T1.jpg`,
        headerText: '<h5>PAINT ME - THE COLLECTION</h5>',
        bodyText: `<b>“Self-love these days feels like an act of rebellion,”</b> says <i>Paint Me - The Collection of a Dozen Roses</i> curator and body-positive activist Elle Baez. <b>“Perhaps because it is.”</b><br>Inspired by the intimate scene from the James Cameron film <b><i>Titanic</b></i> where the character Jack draws Rose, Baez has curated a diverse gallery of curvy, chubby, and fat women in all of their glory entitled ‘Paint Me: The Collection of a Dozen Roses’.<br>Posing as Rose with the <b>“Heart of the Ocean”</b> necklace, these women challenge the media’s ingenue tropes by the rebellious and revolutionary acts of gratuitous self-love and demands of praise, admiration, and societal value. Their message is clear: they are here to stake their claim as pinnacles of beauty and desired objects of pop culture’s affection. These <b>Renaissance bodies will reclaim their disinherited throne</b> once again and will not ask for it back, but rise to it together in solidarity.<br> 
As the collection’s center point, Baez’s inspiringly titled self-love anthem <i>“Paint Me”</i> is an attestation of self-importance as Baez takes control of her own story to create a cathartic ballad and pièce de résistance on her own terms. Baez <b>recreates the famous <i>Titanic</i> drawing scene</b> in the song’s music video, featuring a Dominican actor, Fernando Bruno, as Jack.<br>Baez sings, <b>“they tried to paint my curves straight every damn time,”</b> a lyric that portrays the oppressive body shaming that has left no woman unscathed. She encourages others to take their scars and rise with them to find love for themselves because they are a <b>“work of art”</b>. They deserve admiration for every single part of them, as Baez sings <b>“paint me, my curves, my thighs”,</b> just as Jack did for Rose.<br>This <b>fat liberation project</b> intends to dismantle and remove the misogynistic idealism that has hindered large women in today’s society. Baez curated this gallery because these <b>women no longer tolerate being shaped by others</b> or conforming their bodies and talents for other’s use and believe no one should. They aim to prove that <b>there’s enough room at the top of every industry for all bodies</b>.<br>A Universal Truth: Roses are the ultimate representation of timeless beauty. Watch as this collection of a dozen Roses incites revolution as it shares the undeniable truth: <b>that all bodies deserve to be admired, loved, and to have their own <i>Titanic</i> moment.</b>`,
    },

    // WALL 3
    {
        paintingId: 'W3P1',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W3P1a.jpg`,
        headerText: '<h5>MARLEE DATER</h5>',
        bodyText: `Marlee Dater is an actor, singer, dancer, writer and certified Reiki practitioner and intuitive coach. She is also currently undertaking certification in Authentic Tantra, as well as JourneyDance. Marlee co-founded the Curvy Artists Collective with Elle Baez in September 2019 to promote size inclusivity in the arts, and to provide support for curvy women/non-binary/femme individuals.<br>Her aim is to be an inspiration for ALL bodies and abilities; to heal and enlighten through energy work, self exploration, pleasure practice, and movement/dance. Marlee loves the inner work of discovering more and more of who we are underneath all of the societal conditioning and past negative experiences that keep us small.<br>“For so long I didn't think I was worthy of love because I never saw my body type being loved in the media. There were no curvy romantic leads on the screen. Society was constantly reminding me that my body ‘wasn't right.’”<br>“I am a work of art because I am alive. I am literally a miracle. I am literally made of star stuff! And I use my life to inspire and bring joy to others.” `,
        ContactURL: 'https://www.marleedater.com',
        urlLink1Label: 'REIKI',
        urlLink1: 'https://www.marleedater.com/healing',
        urlLink2Label: 'CAC',
        urlLink2: 'https://www.instagram.com/curvyartistscollective',
        instagramUrl: 'https://www.instagram.com/marlee.dater',
    },

    {
        paintingId: 'W3P2',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W3P2a.jpg`,
        headerText: '<h5>CLARISSA MARIE LIGON</h5>',
        bodyText: `Clarissa Marie Ligon aka Chubbi Bunni Sinclair is a storyteller, actor, singer, producer, stage manager, and burlesque performer from Brooklyn, New York. She uses her curves, color, and unapologetic energy to entertain audiences as she takes up the space her body deserves.<br>Through song and burlesque, she’s found a way to create art that is both cathartic and political. “I’ve come to the realization that it’s crucial to be unapologetic about my body as a Black woman in America. That sometimes my presence is itself a political statement.” Her proudest achievement has been creating a variety show she co-produces entitled “The Show!” The Show has created a space where artists can showcase their talent and skills to an audience of fellow artists and art lovers.<br>“I am a work of art because I am a one-of-a-kind goddess. I am black, I am fat, I am a woman, and I am powerful. All these qualities make me who I am: a unique and fabulous work of art.”`,
        ContactURL: 'https://www.clarissamarieligon.com',
        instagramUrl: 'https://www.instagram.com/clarissamarieligon/',
        urlLink1Label: 'THE SHOW',
        urlLink1: 'https://www.instagram.com/theshowbk',
        urlLink2Label: 'CHUBBI BUNNI',
        urlLink2: 'https://www.instagram.com/chubbibunnisinclair',
    },
    {
        paintingId: 'W3P3',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W3P3a.jpg`,
        headerText: '<h5>JEN PONTON</h5>',
        bodyText: `Jen Ponton is an actor/producer that’s been seen in 30 Rock, Dietland, and The Unbreakable Kimmy Schmidt. She’s been a fat liberation activist for 12 years and is fiercely committed to parity in body politics, centering Black queer voices that liberated and educated us, and tearing down the white supremacist patriarchy.<br>Her mission is to normalize seeing fat talent on film and television. “In a cis-white body, some of the most powerful work I can do is showing myself without shame on camera and on stage--inhabiting roles that aren't defined by size or fat girl tropes.”<br>One day she dreams of running a production company that produces binge-worthy, culture-shifting television that normalizes fat bodies, hires and uplifts Black and Brown queer voices, and tells stories that examine and dissect the delusion of white feminism.<br>“I am a work of art because I am forever embodying myself more and more. Through changes in my body and size, grief and losses, discovery and evolution, I am always finding more ways to love the presentation of my body and how I express myself in the world.”`,
        ContactURL: 'https://www.jenponton.com',
        twitterUrl: 'https://twitter.com/JenPonton',
        instagramUrl: 'https://www.instagram.com/jenponton/',
        imdbUrl: 'https://www.imdb.com/name/nm3047181/',
    },

    {
        paintingId: 'W3P4',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W3P4a.jpg`,
        headerText: '<h5>SOPHIE TURNER</h5>',
        bodyText: `Sophie Turner is a model and influencer from Edinburgh, Scotland. When she was 21 years old, she moved from the UK to New York City to pursue the life she had always dreamt of.<br>Sophie’s online content shows off her style as a curvier woman and hopes to inspire other women to feel confident in their own skin! Her ultimate dream is to be featured on a billboard in Times Square.<br>“Whatever someone does with their body is their choice and other people shouldn’t have an opinion--fat or skinny, we are all beautiful!” Sophie would love to see a plus size character be loved, like her favorite: Ali from The Notebook, without her body being the center of the narrative. “I am a work of art because there’s only one me and I’m embracing it.”`,
        tiktokUrl: 'https://www.tiktok.com/@sophieeturner?source=h5_m',
        instagramUrl: 'https://www.instagram.com/sophieeturner/?hl=en',
        youtubeUrl: 'https://www.youtube.com/channel/UCrUKXV1n9nX4i-qrawdJWyQ?view_as=subscriber',
        facebookUrl: 'https://www.facebook.com/thisissophieeturner',
    },
// WALL 4
    {
        paintingId: 'W4P1',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W4P1a.jpg`,
        headerText: '<h5>NATI NOELI</h5>',
        bodyText: `Nati Noeli is a Latinx supermom and influencer from Miami, Florida. She is a “moon goddess with a gypsy soul” and loves herself unconditionally, fiercely, and shamelessly both in real life and on herInstagram. Her big, beautiful body comes with big, beautiful opinions which she isn’t afraid to share with the world.<br>Nati’s followers love her daily affirmations which call to motivate them to keep fighting for their dreams in life. As a mother, Nati inspires other mothers to be the powerful, strong, independent bosses they were meant to be; to be exemplary role models for their children so they develop healthy self-love and self-worth.<br>She would tell her 10 year old self, “You will grow up to be a badass!” “I am a work of art because my body is adorned in a constellation of stretch marks and scars that prove my strength and resilience.”`,
        instagramUrl: 'http://www.instagram.com/nati.noeli',
    },

    {
        paintingId: 'W4P2',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W4P2a.jpg`,
        headerText: '<h5>PEACH</h5>',
        bodyText: `Peach is a rapper (p/k/a Peachkka), adult content creator, and creative director located in Harrisburg, Pennsylvania and London. Her ethos is to be unapologetically herself, and she solidifies her brand of fiery female empowerment with bold lyrics, catchy beats, and big girl feats.<br>To her, fat women should not only be sexualized, but romanticized. She wants to be the “fat chick on TV I wanted to see as a kid” and inspire other kids to feel comfortable in their own skin. Peach’s goal is to get rid of the term “plus-sized” and make big bodies a normality in society.<br>Her biggest dream is to have a late-night talk show in which she can show all parts of her personality, her squad, her fantasies, and expose the world to her “Peachkka” glory. She would tell her 10 year old self, “Never dim your light for anyone. . . keep performing.” “I am a work of art because I command attention and power just by existing.”`,
        instagramUrl: 'https://www.instagram.com/peachkka/',
        spotifyUrl: 'https://open.spotify.com/artist/1r2v9WnToBlX0FPBDG9ak7?si=oie9ttirTLyAv6du7fcdBA',
        urlLink1Label: 'PRODUCTIONS',
        urlLink1: 'https://www.instagram.com/itsapeachproduction/',
    },
    {
        paintingId: 'W4P3',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W4P3a.jpg`,
        headerText: '<h5>CRYSTAL RENEE</h5>',
        bodyText: `Crystal Renee is an entrepreneur and model whose passion is fashion for all bodies regardless of shape, size, shade, and gender. She has taken on her mission by founding the body positive fashion show, FOR ALL BODIES.<br>Her ultimate goal is to create a television network which provides opportunities and a platform for all bodies to be represented. Crystal wants curvy mainstream superheroes in media that show plus size women holding truth, power, and can save the day, too!<br>It took some time for Crystal to love her curves and curls, but now that she loves every part of herself she wants to help others find that same love.“I am a work of art because through all my trials and tribulations I still am worthy. They have shaped me into an individual that sees beauty among the struggle.”`,
        instagramUrl: 'https://www.instagram.com/1crystalrenee/',
        urlLink1Label: 'FORALLBODIES',
        urlLink1: 'https://www.instagram.com/forallbodiesshow/',
        urlLink2Label: 'PRESS',
        urlLink2: 'https://www.plus-model-mag.com/2019/10/this-model-using-runway-need-inclusivity-modeling-industry/',
    },
    {
        paintingId: 'W4P4',
        videoId: null,
        videoURL: null,
        imageURL: `/paintings/W4P4a.jpg`,
        headerText: '<h5>KATHRYN ALLISON</h5>',
        bodyText: `Kathryn Allison is a Broadway actress, recording artist, and performer from New York City and was last seen in Wicked, Aladdin, and is cast in the upcoming Broadway revival of Company alongside Patti Lupone and Katrina Lenk.<br>Kathryn uses her platform to promote size, gender, and LGBTQ+ inclusivity. Kathryn’s ethos is “ALL bodies are good bodies and ALL Black Lives Matter.” Her dream is to one day win a Tony Award and to continue fostering and uplifting artists from marginalized communities.<br>“I am a work of art because my body allows me to live the life that I imagined and dreamed for myself when I was younger, and I am really proud of that.” She would tell the 10 year old Kathryn that her dreams are valid and that she is worthy of them in every single way.  “Don’t let anybody’s comments or your fear of their judgement get in the way of what you want.”`,
        ContactURL: 'https://kathrynallison.me/',
        instagramUrl: 'https://www.instagram.com/kathryndallison/',
        youtubeUrl: 'https://www.youtube.com/channel/UCciZ4nrk5iAI0KltL4lOQGA',
        spotifyUrl: 'https://open.spotify.com/artist/5ESud0qY7YznYeQ8yY5pDM?si=IdYP6jJOQ2qntDDnnUuWmQ',
    },

    /* Elle Baez Annotations */

];

export {AnnotationsDB};
