import React from "react";
import VideoComponent from "./VideoComponent";

const VideosComponent = (props) => {
    return (
        <div>
            <VideoComponent id="W2V1" src={process.env.PUBLIC_URL + `/videos/W2V1.mp4`}/>

        </div>
    )
}

export default VideosComponent;
