
import {Euler, Vector3,Scene} from "three";

const FLOOR_NUMBER = {
    BOTTOM_FLOOR: 1,
    TOP_FLOOR:2,
};
// Here we split by Artists Names
const CameraLocationsDB = [

    {
        cameraLocationId: 'W1P1',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Bianca Rogoff',
        cameraPosition: null, // use THREE.Vector3
        cameraRotation: null,  //use THREE.Euler
    },
    {
        cameraLocationId: 'W3P2',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Clarissa Marie Ligon',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W1P2',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Constance Smith',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W4P3',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Crystal Renee',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W2P1',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Elle Baez',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W3P3',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Jen Ponton',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W4P4',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Kathryn Allison',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W3P1',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Marlee Dater',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W1P4',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Morgan Louise',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W4P1',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Nati Noeli',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W4P2',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Peach',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W1P3',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Priscilla Del Castillo',
        cameraPosition: null,
    },
    {
        cameraLocationId: 'W3P4',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Sophie Turner',
        cameraPosition: null,
    },
];

export {CameraLocationsDB, FLOOR_NUMBER};